import React, { useState, useEffect, useCallback } from 'react';
import CoachsStatut from './CoachsStatut/UserStatut';
import FilterAddCoachs from './FilterAddCoachs/filteraddcoachs';
import SuccessPopup from './SucessPopup/SuccessPopup';
import './Addusers.css'; 
import { IoCloseOutline } from 'react-icons/io5';  
import Email from '../Email/Email';
import SubscriptionPopup from './SubscriptionPopup';
import useApi from '../../api/useApi';

const AddCoachs = () => {
    const [coaches, setCoaches] = useState([]);
    const [filteredCoaches, setFilteredCoaches] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
    const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
    const [isSubscriptionPopupOpen, setIsSubscriptionPopupOpen] = useState(false);  
    const [editIndex, setEditIndex] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [packs, setPacks] = useState([]);
    const { request } = useApi();

    const fetchData = useCallback(async () => {
        const coachId = localStorage.getItem('id');  

        const packsResponse = await request('get', '/userpacks');
        setPacks(packsResponse.filter(pack => pack.userId === coachId));

        const subscriptionsResponse = await request('get', `/subUser/by-coach/${coachId}`);
        console.log('Subscriptions Response:', subscriptionsResponse);

        const coachSubscriptionsMap = new Map();

        for (const sub of subscriptionsResponse) {
            if (!coachSubscriptionsMap.has(sub._id)) {
                const userResponse = await request('get', `/users/${sub.UserId}`);

                coachSubscriptionsMap.set(sub._id, {
                    _id: sub._id,  
                    userId:sub.UserId,
                    FullName: userResponse.name,
                    Pack: sub.packId.name,
                    Type: sub.packId.TypeCoaching[0],
                    Date: new Date(sub.startDate).toLocaleDateString(),
                    Category: sub.packId.TypePresence[0],
                    status: sub.subscriptionStatus || 'pending',
                });
            }
        }

        const coachSubscriptions = Array.from(coachSubscriptionsMap.values());

        setCoaches(coachSubscriptions);
        setFilteredCoaches(coachSubscriptions);
    }, [request]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const closePopup = () => {
        setIsPopupOpen(false);
        setEditIndex(null);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
    };

    const closeSuccessPopup = () => {
        setIsSuccessPopupOpen(false);
    };

    const deleteCoach = async (subscriptionId) => {
        try {
            await request('delete', `/subUser/${subscriptionId}`);
            await fetchData(); 
        } catch (error) {
            console.error('Error deleting subscription:', error);
        }
    };

    const handleFilterChange = ({ fullname, date, status }) => {
        const filtered = coaches.filter(coach => {
            return (
                (!fullname || coach.FullName.toLowerCase().includes(fullname.toLowerCase())) &&
                (!date || coach.Date.includes(date)) &&
                (!status || coach.status.toLowerCase() === status.toLowerCase())
            );
        });
        setFilteredCoaches(filtered);
    };

    const handleCheckboxChange = (index) => {
        setSelectedIndex(index);
    };

    const handleButtonClick = () => {
        if (selectedIndex !== null) {
            const updatedCoaches = [...coaches];
            updatedCoaches[selectedIndex].status = 'Payed';
            setCoaches(updatedCoaches);
            setFilteredCoaches(updatedCoaches);
            setSelectedIndex(null);
        } else {
            setIsSubscriptionPopupOpen(true);  
        }
    };

    const closeEmailPopup = () => {
        setIsEmailPopupOpen(false);
    };

    const closeSubscriptionPopup = () => {
        setIsSubscriptionPopupOpen(false);
    };

    const handleSubscriptionSubmit = async (subscriptionData) => {
        try {
            console.log('Submitting subscription...');
          
         
            await fetchData(); 
            setIsSubscriptionPopupOpen(false);  
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="adco-addCoachsPage">
            <FilterAddCoachs onFilterChange={handleFilterChange} />
            <CoachsStatut 
                coaches={filteredCoaches} 
                onDeleteCoach={deleteCoach} 
                onCheckboxChange={handleCheckboxChange} 
            />
            <div className="adco-addCoachButtonContainer">
                <button 
                    className="adco-addCoachButton"
                    onClick={handleButtonClick}
                >
                    {selectedIndex !== null ? 'Check' : 'Add Subscription'}
                </button>
            </div>

            {isPopupOpen && (
                <div className="adco-popupOverlay">
                    <div className="adco-popupContent">
                        <button className="adco-closeButton" onClick={closePopup}>
                            <IoCloseOutline /> 
                        </button>
                        <h2>{editIndex !== null ? 'Edit Coach' : 'Add Coach'}</h2>
                        <form onSubmit={handleFormSubmit}>
                        </form>
                    </div>
                </div>
            )}

            {isSuccessPopupOpen && (
                <SuccessPopup 
                    message="This Email Contains an Account. The Coach Must Accept this Partnership." 
                    onClose={closeSuccessPopup} 
                />
            )}

            {isEmailPopupOpen && (
                <Email onClose={closeEmailPopup} />
            )}

            {isSubscriptionPopupOpen && (
                <SubscriptionPopup
                    onClose={closeSubscriptionPopup}
                    onSubmit={handleSubscriptionSubmit}  
                    packs={packs}  
                />
            )}
        </div>
    );
};

export default AddCoachs;