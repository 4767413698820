import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import styles from './socialMediaLinks.module.css';

function SocialMediaLinks() {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [coachId] = useState(localStorage.getItem('id'));
  const [socialLinks, setSocialLinks] = useState({
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: ''
  }); 

  useEffect(() => {
    const fetchCoachDetails = async () => {
      try {
        setLoading(true);
        const response = await api.request('GET', `coaches/${coachId}`);
        console.log(response);

        const { facebook, instagram, youtube, twitter } = response;

        setSocialLinks({ facebook, instagram, youtube, twitter }); // Set social links
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCoachDetails();
  }, []);

  const handleUpdateCoach = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();
      // Append social media links
      formData.append('facebook', socialLinks.facebook);
      formData.append('instagram', socialLinks.instagram);
      formData.append('youtube', socialLinks.youtube);
      formData.append('twitter', socialLinks.twitter);

      await api.request('PUT', `coaches/${coachId}`, formData, {
        'Content-Type': 'multipart/form-data',
      });

      alert('Mise à jour réussie');
    } catch (err) {
      console.error('Erreur lors de la mise à jour :', err);
      alert('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.CoachDCs}>
      <div className={styles.CoachDetails}>
        <h2 className={styles.formTitle}>social media links</h2>
        {loading && <p>Chargement...</p>}
        {error && <p>Erreur : {error.message}</p>}
        
        <form className={styles.FormContainer} onSubmit={handleUpdateCoach}>
          <div className={styles.formGroup}>
            <label htmlFor="facebook">Lien Facebook</label>
            <input
              type="url"
              id="facebook"
              placeholder="https://www.facebook.com/example"
              value={socialLinks.facebook}
              onChange={(e) => setSocialLinks({ ...socialLinks, facebook: e.target.value })}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="instagram">Lien Instagram</label>
            <input
              type="url"
              id="instagram"
              placeholder="https://www.instagram.com/example"
              value={socialLinks.instagram}
              onChange={(e) => setSocialLinks({ ...socialLinks, instagram: e.target.value })}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="youtube">Lien YouTube</label>
            <input
              type="url"
              id="youtube"
              placeholder="https://www.youtube.com/example"
              value={socialLinks.youtube}
              onChange={(e) => setSocialLinks({ ...socialLinks, youtube: e.target.value })}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="twitter">Lien Twitter</label>
            <input
              type="url"
              id="twitter"
              placeholder="https://www.twitter.com/example"
              value={socialLinks.twitter}
              onChange={(e) => setSocialLinks({ ...socialLinks, twitter: e.target.value })}
              required
            />
          </div>
          <div className={styles.BtnC}>
            <button type="submit" className={styles.saveButton} disabled={loading}>
              Save
            </button>
          </div>
        </form>

        {/* Section des liens des médias sociaux */}
        <div className={styles.socialMediaLinks}>
         
          <ul>
            <li>
              <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer">
              
              </a>
            </li>
            <li>
              <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer">
               
              </a>
            </li>
            <li>
              <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer">
               
              </a>
            </li>
            <li>
              <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer">
               
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SocialMediaLinks;
