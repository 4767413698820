import React, { useEffect, useState } from 'react';
import './MenuCard.css'; 
import { FaDumbbell, FaPlus, FaEdit, FaTrash, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';  
import { PiBowlFoodFill } from 'react-icons/pi';
import Food from '../Food/Food';  
import ExerciseCard from '../Food/ExerciseCard';  
import useApi from '../../api/useApi';

const MenuCard = () => {
  const [showFoodModal, setShowFoodModal] = useState(false);
  const [showFoodCard, setShowFoodCard] = useState(false);
  const [showExerciseCard, setShowExerciseCard] = useState(false);  
  const [submittedFood, setSubmittedFood] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  const [editFoodData, setEditFoodData] = useState(null); 
  const [expandedItemId, setExpandedItemId] = useState(null); // Track expanded accordion item
  const { request } = useApi();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const coachId = localStorage.getItem('id');
    setUserId(coachId); 
    getFood(coachId);
  }, []);  

  const handleFoodClick = () => {
    setShowFoodCard(true);  
    setShowExerciseCard(false);  
    setEditFoodData(null);  
    setShowFoodModal(false);  
  };

  const handleExerciseCardClick = () => {
    setShowExerciseCard(true);  
    setShowFoodCard(false);  
  };

  const closeFoodModal = () => {
    setShowFoodModal(false);
  };

  const handleFoodSubmit = async (data) => {
    await getFood(userId);  
    setShowFoodModal(false);  
  };

  const getFood = async (coachId) => {
    setIsLoading(true);
    try {
      const data = await request('get', `nutrition?coachId=${coachId}`);
      setSubmittedFood(data);  
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to fetch nutrition plan. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (food) => {
    setEditFoodData(food); 
    setShowFoodModal(true);  
  };

  const handleDelete = async (foodId) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      setIsLoading(true);
      try {
        await request('delete', `nutrition/${foodId}`);
        await getFood(userId);  
      } catch (error) {
        console.error('Error:', error);
        alert('Failed to delete nutrition plan. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleAccordion = (id) => {
    setExpandedItemId(expandedItemId === id ? null : id); // Toggle the accordion item
  };

  return (
    <div className="co-menu-card-container">
      <div className="co-menu-card">
        <div className="co-menu-item" onClick={handleExerciseCardClick}>
          <FaDumbbell className="co-menu-icon" />
          <span>Exercices</span>
        </div>
        <div className="co-menu-item" onClick={handleFoodClick}>
          <PiBowlFoodFill className="co-menu-icon" />
          <span>Food</span>
        </div>
      </div>

      {showExerciseCard && (
        <div className="co-exercise-card-container">
          <ExerciseCard 
            onAddExercise={() => {
              console.log("Add Exercise button clicked");
            }}
          />
        </div>
      )}

      {showFoodCard && (
        <div className="co-food-card-container">
          <div className="co-add-food-card">
            <button
              className="co-add-food-button"
              onClick={() => setShowFoodModal(true)}  
              aria-label="Add Food"
            >
              <FaPlus className="co-add-food-icon" />
              <span>Add Food</span>
            </button>

            {isLoading && <p>Loading...</p>}  

            {submittedFood.length > 0 && (
              <div className="co-submitted-food-details">
                {submittedFood
                  .filter((food) => food.coachId === userId)
                  .map((food) => (
                    <div key={food._id} className="co-food-item">
                      <div className="co-food-item-header" onClick={() => toggleAccordion(food._id)}>
                        <h3>{food.plateName}</h3>
                        {expandedItemId === food._id ? <FaChevronUp /> : <FaChevronDown />}
                      </div>
                      {expandedItemId === food._id && (
                        <div className="co-food-item-body">
                          <ul>
                            {food.ingredients.map((ingredient, idx) => (
                              <li key={idx}>
                                {ingredient.ingredientName} - {ingredient.measure}
                              </li>
                            ))}
                          </ul>
                          <div className="co-food-item-actions">
                            <button
                              className="co-edit-buttonFood"
                              onClick={() => handleEdit(food)}
                              aria-label="Edit"
                            >
                              <FaEdit />
                            </button>
                            <button
                              className="co-delete-buttonFood"
                              onClick={() => handleDelete(food._id)}
                              aria-label="Delete"
                            >
                              <FaTrash />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}

      {showFoodModal && (
        <div className="co-food-modal">
          <button
            className="co-food-modal-close"
            onClick={closeFoodModal}
            aria-label="Close Food Modal"
          >
            <FaTimes />
          </button>
          <Food
            onSubmit={handleFoodSubmit}
            closeModal={closeFoodModal}
            existingData={editFoodData}  
          />
        </div>
      )}
    </div>
  );
};

export default MenuCard;
