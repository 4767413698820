import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './accountdetails.css';

const Modal = ({ show, onClose, message }) => {
  if (!show) return null;

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal">
        <div className="modal-content">
          <div className="checkmark-circle">
            <div className="background"></div>
            <div className="checkmark"></div>
          </div>
          <p className='message'>{message}</p>
        </div>
      </div>
    </div>
  );
};

const AccountDetails = () => {
  const [gymDetails, setGymDetails] = useState({
    name: 'California Gym Sousse',
    email: 'CaliforniaGym@gmail.com',
    city: '',
    address: '',
    postalCode: '',
    logoUrl: 'https://wefranchiz.com/wp-content/uploads/2023/05/california-gym.jpg'
  });

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGymDetails({
      ...gymDetails,
      [name]: value
    });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setGymDetails({
        ...gymDetails,
        logoUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async () => {
    try {
      const response = await axios.post('https://jsonplaceholder.typicode.com/posts', gymDetails);
      if (response.status === 201) {
        setShowModal(true);
      } else {
        console.error('Failed to save data:', response);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/Overview');
  };

  return (
    <div className="account-details">
      <h2 className='title_details'>Account Details</h2>
      <div className="account-details-form">
        <div className="logo-container">
          <img src={gymDetails.logoUrl} alt="Gym Logo" className="gym-logo" />
          <input
            type="file"
            name="logo"
            onChange={handleLogoChange}
            id="logo-upload"
            className="logo-input"
          />
        </div>
        <div className='modifier_container'>
          <label htmlFor="logo-upload" className="logo-upload-label">
            Modifier le logo
          </label>
        </div>
        <div className='gymname_container'>
          <h3 className='gymname'>{gymDetails.name}</h3>
        </div>
        <input
          type="text"
          name="name"
          value={gymDetails.name}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          value={gymDetails.email}
          onChange={handleChange}
        />
        <input
          type="text"
          name="city"
          placeholder="City"
          value={gymDetails.city}
          onChange={handleChange}
        />
        <input
          type="text"
          name="address"
          placeholder="Full Address"
          value={gymDetails.address}
          onChange={handleChange}
        />
        <input
          type="text"
          name="postalCode"
          placeholder="Postal Code"
          value={gymDetails.postalCode}
          onChange={handleChange}
        />
        <div className='details_btn'>
          <button onClick={handleSave} className="save-button">Save</button>
        </div>
      </div>
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        message="Saved!"
      />
    </div>
  );
};

export default AccountDetails;
