import React from 'react';
import { Link } from 'react-router-dom';
import './top-shop-rev.css';
import constantImages from '../../../../../ConstantImages/constantimages';  

const TopShopRev = () => {
  const data = [
    {
      name: 'hamza',
      price: 49,
      category: 'Category1',
      orders: 80,
      gains: 3902,
    },
    {
      name: 'hedi',
      price: 20,
      category: 'Category2',
      orders: 70,
      gains: 1102,
    },
    {
      name: 'amine',
      price: 50,
      category: 'Category3',
      orders: 80,
      gains: 4302,
    },
  ];

  return (
    <div className="top-shop-rev-container">
      <div className="top-shop-rev-header">
        <h2 className="top-shop-rev-title">Top Coachs</h2>
        <div className="top-shop-rev-show-more">
          
        </div>
      </div>
      <table className="top-shop-rev-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Category</th>
            <th>Number of Orders</th>
            <th>Total Gains</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="product-info">
                  <input type="checkbox" className="checkbox-spacing" />
                  <img src={constantImages.product} alt="product" className="product-image" />  
                  <span className="product-name">{item.name}</span>
                </div>
              </td>
              <td>{item.price}</td>
              <td>{item.category}</td>
              <td>{item.orders}</td>
              <td className="top-shop-rev-gains">{item.gains} TND</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopShopRev;