import React, { useState } from 'react';
import './Sidebar.css';
import { 
  FaHome, FaUser,  FaTv, FaCog, FaQuestionCircle, FaEnvelope, FaBars, FaTimes, 
  FaInfo,  FaShieldAlt,  FaBell,  
  FaLink 
} from 'react-icons/fa';
import { BsFileTextFill } from 'react-icons/bs';
import { FaMoneyCheckDollar } from "react-icons/fa6";

import { GiGymBag } from 'react-icons/gi';
import { IoIosImages } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { useCoachPageTitle } from '../../context/CoachPageTitleContext';

const SidebarCoach = () => {
  const [open, setOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeLink, setActiveLink] = useState('/Overview');
  const navigate = useNavigate();
  const { setPageTitle } = useCoachPageTitle();

  const toggleMenu = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu(null);
    } else {
      setActiveMenu(menu);
      switch(menu) {
        case 'request':
          navigate('/coach/subscription/Addreq');
          setActiveLink('/coach/subscription/Addreq');
          setPageTitle('Request');
          break;
        case 'display':
          navigate('/coach/display/CoachDetails');
          setActiveLink('/coach/display/CoachDetails');
          setPageTitle('Display');
          break;
        case 'settings':
          navigate('/coach/Settings/notification');
          setActiveLink('/coach/Settings/notification');
          setPageTitle('Settings');
          break;
        default:
          break;
      }
    }
  };

  const handleLinkClick = (link, title) => {
    if (!link.startsWith('/coach/subscription') && !link.startsWith('/coach/display') && !link.startsWith('/coach/Settings')) {
      setActiveMenu(null); 
    }
    setActiveLink(link);
    navigate(link);
    setPageTitle(title);
  };

  return (
    <div className="sidgy-sidebar_container">
      <div className={`sidgy-sidebar ${open ? '' : 'sidgy-sidebar-closed'}`}>
        <div className="sidgy-toggle-button" onClick={() => setOpen(!open)}>
          {open ? <FaTimes /> : <FaBars />}
        </div>
        <div className="sidgy-logo">
          <img src="gymlogo.webp" alt="Gym logo" />
        </div>
        <div className="sidgy-menu">
          <h3 className={`${open ? '' : 'sidgy-hidden'}`}>DASHBOARD</h3>
          <ul>
            <li className={activeLink === '/Overviewcoach' ? 'active' : ''} onClick={() => handleLinkClick('/Overviewcoach', 'Overview')}>
              <Link to="/Overviewcoach">
                <div className="sidgy-icon"><FaHome /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>Overview</span>
              </Link>
            </li>
            <li className={activeLink === '/coach/subscription/Addreq' ? 'active' : ''} onClick={() => handleLinkClick('/coach/subscription/Addreq', 'Request')}>
              <Link to="/coach/subscription/Addreq">
                <div className="sidgy-icon"><FaUser /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>Request</span>
              </Link>
            </li>
            <li className={activeLink === '/coach/Menucard' ? 'active' : ''} onClick={() => handleLinkClick('/coach/Menucard', 'Backlog')}>
              <Link to="/coach/MenuCard">
                <div className="sidgy-icon"><BsFileTextFill /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>Backlog</span>
              </Link>
            </li>
            <li onClick={() => toggleMenu('display')} className={`display-menu ${activeMenu === 'display' ? 'active' : ''}`}>
              <div className="sidgy-icon"><FaTv /></div>
              <span className={`${open ? '' : 'sidgy-hidden'}`}>Display</span>
            </li>
            <li onClick={() => toggleMenu('settings')} className={`settings-menu ${activeMenu === 'settings' ? 'active' : ''}`}>
              <div className="sidgy-icon"><FaCog /></div>
              <span className={`${open ? '' : 'sidgy-hidden'}`}>Settings</span>
            </li>
          </ul>
          <h3 className={`${open ? '' : 'sidgy-hidden'}`}>HELP AND SUPPORT</h3>
          <ul>
            <li className={activeLink === '/coach/FAQ' ? 'active' : ''} onClick={() => handleLinkClick('/coach/FAQ', 'FAQ')}>
              <Link to="/coach/FAQ">
                <div className="sidgy-icon"><FaQuestionCircle /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>FAQ</span>
              </Link>
            </li>
            <li className={activeLink === '/coach/Contact' ? 'active' : ''} onClick={() => handleLinkClick('/coach/Contact', 'Contact Us')}>
              <Link to="/coach/Contact">
                <div className="sidgy-icon"><FaEnvelope /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>Contact Us</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={`sidgy-content ${open ? '' : 'sidgy-content-expanded'} ${activeMenu ? 'sidgy-content-shifted' : ''}`}>
        {activeMenu === 'display' && (
          <div className="sidgy-submenu-card">
            <ul className="sidgy-submenu">
              <li className={activeLink === '/coach/display/coachDetails' ? 'active' : ''} onClick={() => handleLinkClick('/coach/display/CoachDetails', 'Display')}>
                <Link to="/coach/display/CoachDetails">
                  <div className="sidgy-icon"><FaInfo /></div>
                  <span>coach Details</span>
                </Link>
              </li>
              <li className={activeLink === '/coach/display/SocialMediaLinks' ? 'active' : ''} onClick={() => handleLinkClick('/coach/display/SocialMediaLinks', 'Display')}>
                <Link to="/coach/display/SocialMediaLinks">
                  <div className="sidgy-icon"><FaLink /></div>
                  <span>SocialMediaLinks</span>
                </Link>
              </li>
              <li className={activeLink === '/coach/display/CreatePack' ? 'active' : ''} onClick={() => handleLinkClick('/coach/display/CreatePack', 'Display')}>
                <Link to="/coach/display/pricing">
                  <div className="sidgy-icon"><FaMoneyCheckDollar /></div>
                  <span>Pricing</span>
                </Link>
              </li>
              <li className={activeLink === '/coach/display/activities' ? 'active' : ''} onClick={() => handleLinkClick('/coach/display/activitiess', 'Display')}>
                <Link to="/coach/display/activities">
                  <div className="sidgy-icon"><GiGymBag /></div>
                  <span>activity</span>
                </Link>
              </li>
              <li className={activeLink === '/coach/display/Gallerie' ? 'active' : ''} onClick={() => handleLinkClick('/coach/display/Gallerie', 'Display')}>
                <Link to="/coach/display/Gallerie">
                  <div className="sidgy-icon"><IoIosImages /></div>
                  <span>Gallerie</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
        {activeMenu === 'settings' && (
          <div className="sidgy-submenu-card">
            <ul className="sidgy-submenu">
             
              <li className={activeLink === '/coach/Settings/notification' ? 'active' : ''} onClick={() => handleLinkClick('/coach/Settings/notification', 'Settings')}>
                <Link to="/coach/Settings/notification">
                  <div className="sidgy-icon"><FaBell /></div>
                  <span>Notification</span>
                </Link>
              </li>
              <li className={activeLink === '/coach/Settings/security' ? 'active' : ''} onClick={() => handleLinkClick('/coach/Settings/security', 'Settings')}>
                <Link to="/coach/Settings/security">
                  <div className="sidgy-icon"><FaShieldAlt /></div>
                  <span>Security</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarCoach;