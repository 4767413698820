import React from 'react';
import './Detailsuser.css';

const Detailsuser= ({ userDetails }) => {
  return (
    <div className="detusco-details-card">
      <div className="detusco-details-header">
        <h2 className="detusco-user-name">{userDetails.name}</h2>
        <p className="detusco-gender">{userDetails.gender}</p>
      </div>
      <div className="detusco-details-content">
        <div className="detusco-details-info">
          <div className="detusco-info-pair">
            <div className="detusco-info-item">
              <h4>Location</h4>
              <p>{userDetails.location}</p>
            </div>
          </div>
          <div className="detusco-info-pair">
            <div className="detusco-info-item">
              <h4>Weight</h4>
              <p>{userDetails.weight} kg</p>
            </div>
          </div>
          <div className="detusco-info-pair">
            <div className="detusco-info-item">
              <h4>Height</h4>
              <p>{userDetails.height} cm</p>
            </div>
          </div>
          <div className="detusco-info-pair">
            <div className="detusco-info-item">
              <h4>Date of Birth</h4>
              <p>{new Date(userDetails.dateOfBirth).toLocaleDateString()}</p>
            </div>
          </div>
        </div>
        {/* You can add an image for the user if you have an image property */}
        {/* <div className="detusco-details-logo">
          <img src={userDetails.image} alt="User" className="detusco-user-image" />
        </div> */}
      </div>
    </div>
  );
};

export default Detailsuser;
