import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash, FaEdit, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import axiosInstance from '../../api/axiosInstance'; 
import Exercise from '../Food/Exercice'; 
import './ExerciseCard.css'; 

const ExerciseCard = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentExercise, setCurrentExercise] = useState(null);
  const [activeExerciseId, setActiveExerciseId] = useState(null); // State to store active exercise ID
  const [userId, setUserId] = useState(null); // State to store user ID (connected user)

  // Fetch exercises associated with the logged-in coach
  const fetchExercises = async () => {
    setLoading(true);
    const coachId = localStorage.getItem('id'); 
    setUserId(coachId); // Store the user ID in the state for display

    try {
      const response = await axiosInstance.get(`/exercises?coachId=${coachId}`);
      setExercises(response.data);
    } catch (err) {
      setError('Error fetching exercises');
    } finally {
      setLoading(false);
    }
  };

  // Fetch exercises on component mount
  useEffect(() => {
    fetchExercises();
  }, []);

  // Handles adding a new exercise
  const handleAddExerciseClick = () => {
    setCurrentExercise(null); 
    setShowPopup(true);
  };

  // Closes the popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

 
  const handleEditExercise = (exercise) => {
    setCurrentExercise(exercise); 
    setShowPopup(true);
  };

 
  const handleDeleteExercise = async (exerciseId) => {
    if (window.confirm('Are you sure you want to delete this exercise?')) {
      try {
        await axiosInstance.delete(`/exercises/${exerciseId}`);
        setExercises(exercises.filter((exercise) => exercise._id !== exerciseId));
      } catch (err) {
        setError('Error deleting exercise');
      }
    }
  };

  
  const toggleAccordion = (exerciseId) => {
   
    setActiveExerciseId((prevId) => (prevId === exerciseId ? null : exerciseId)); 
  };

  return (
    <div className="exercise-card">
      {/* Display the logged-in user's ID */}
     

      {/* Button to trigger adding a new exercise */}
      <button
        className="add-exercise-button"
        onClick={handleAddExerciseClick}
        title="Click to add a new exercise"
        disabled={loading} // Disable button while loading
      >
        <FaPlus className="add-icon" /> Add Exercise
      </button>

      {/* Conditional rendering of the popup */}
      {showPopup && <Exercise onClose={handleClosePopup} existingData={currentExercise} />}

      {/* Show loading or error state */}
      {loading && <p>Loading exercises...</p>}
      {error && <p className="error">{error}</p>}

      {/* Display the exercises */}
      <div className="exercise-list">
        {exercises.length > 0 ? (
          exercises
            .filter((exercise) => exercise.coachId === userId) 
            .map((exercise) => (
              <div key={exercise._id} className="exercise-item">
                <div className="exercise-header" onClick={() => toggleAccordion(exercise._id)}>
                  <h3>{exercise.exerciseName}</h3>
                  <button className="toggle-button">
                    {activeExerciseId === exercise._id ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                </div>

                {activeExerciseId === exercise._id && ( // Show details if this exercise is active
                  <div className="exercise-details">
                    <img src={exercise.image} alt={exercise.exerciseName} className="exercise-image" />
                    <p>{exercise.description}</p>
                    <p>Duration: {exercise.duration}</p>
                    <p>Repetitions: {exercise.repetionNumber}</p>
                    <p>Sets: {exercise.setsNumber}</p>
                    <p>Rest Between: {exercise.restBetween} seconds</p>
                    <p>Difficulty: {exercise.difficultyLevel}</p>
                    <div className="exercise-item-actions">
  <button
    onClick={() => handleEditExercise(exercise)}
    title="Edit"
  >
    <FaEdit />
  </button>
  <button
    onClick={() => handleDeleteExercise(exercise._id)}
    title="Delete"
    className="delete-button"
  >
    <FaTrash />
  </button>
                    </div>
                  </div>
                )}
              </div>
            ))
        ) : (
          !loading && <p>No exercises found</p>
        )}
      </div>
    </div>
  );
};

export default ExerciseCard;
