import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import styles from './accountdetails.module.css';

function CoachDetails() {
  
  const api = useApi();
 
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
 
  
  const [image, setImage] = useState(null); // New image state for file upload
  const [imageUrl, setImageUrl] = useState(''); // State for the image URL

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [coachId] = useState(localStorage.getItem('id'));

  useEffect(() => {
    const fetchCoachDetails = async () => {
      try {
        setLoading(true);
        const response = await api.request('GET', `coaches/${coachId}`);
        console.log(response);
        const {  email,number, image} = response;
      
        setEmail(email);
        setNumber(number);
      
        
        setImageUrl(image);
        
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCoachDetails();
  }, []);

  const handleUpdateCoach = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();
     
      formData.append('email', email);
      formData.append('number', number);
       // Send description as the first element of the array
     // Add the video URL to formData

      if (image) {
        formData.append('image', image);
      }

      await api.request('PUT', `coaches/${coachId}`, formData, {
        'Content-Type': 'multipart/form-data',
      });

      alert('Mise à jour réussie');
    } catch (err) {
      console.error('Erreur lors de la mise à jour :', err);
      alert('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.CoachDC}>
      <div className={styles.CoachDetails}>
        <h2 className={styles.formTitle}>Détails du Coach</h2>
        {loading && <p>Chargement...</p>}
        {error && <p>Erreur : {error.message}</p>}
        
        {/* Display the current image if it exists */}
       

        <form className={styles.FormContainer} onSubmit={handleUpdateCoach}>
          <div className={styles.formGroup}>
           
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              id="email"
              placeholder="samirloussif@contact.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="phoneNumber">Numéro de Téléphone (Corporatif)*</label>
            <input
              type="tel"
              id="phoneNumber"
              placeholder="0000000000"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
          </div>
        
         
         
         
          <div className={styles.formGroup}>
            <label htmlFor="image">Télécharger une nouvelle image</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
          {imageUrl && (
          <div className={styles.imagePreview}>
            <img src={imageUrl} alt="Coach" className={styles.coachImage} />
          </div>
        )}
          <div className={styles.BtnC}>
            <button type="submit" className={styles.saveButton} disabled={loading}>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CoachDetails;
