import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import './profile.css';  // Rename if necessary
import Detailsuser from './Detailsuser';
import useApiWithErrors from '../../api/useApiWithErrors';


const SuperUserDetails = ({id}) => {
  const location = useLocation();
  const { apiRequest, error } = useApiWithErrors();
  const [userdetails, setuserdetails] = useState({});


  useEffect(() => {
    const fetchuserdetails = async () => {
      const coachId = id;
      console.log(id)
      if (coachId) {
        try {
          const response = await apiRequest('get', `/users/${coachId}`);
          if (response) {
            setuserdetails(response);
          }
        } catch (err) {
          console.error('Error fetching coach details:', err);
        }
      }
    };

    const fetchPaymentHistory = async () => {
      const coachId = location.state?.coachId;
      if (coachId) {
        try {
          const response = await apiRequest('get', `/users/${id}`);
          if (response && response.subscriptions) {
            const subscriptionsWithPackNames = await Promise.all(response.subscriptions.map(async (subId) => {
              const subResponse = await apiRequest('get', `/subuser/${subId}`);
              const packResponse = await apiRequest('get', `/coachpacks/${subResponse.packId}`);
              return {
                ...subResponse,
                packName: packResponse ? packResponse.name : 'Unknown Pack',
              };
            }));
          
          }
        } catch (err) {
          console.error('Error fetching payment history:', err);
        }
      }
    };

    fetchuserdetails();
    
  }, []);

  return (
    <div className="scd-container">
      {error && <div className="error-message">{error}</div>}
      <div className="scd-content">
        <Detailsuser userDetails={userdetails} />
      </div>
    </div>
  );
};

export default SuperUserDetails;
