import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Overviewcoach from '../CoachComponents/OverviewCoachs/overviewcoach';
import LayoutCoach from '../CoachComponents/Layout/Layout';
import CoachDetails from '../CoachComponents/Display/Coachdetails/CoachDetails';
import AddPricing from '../CoachComponents/Display/Pricing/CreatePack';

import DraggableContain from '../CoachComponents/Calender/DraggableCalender';
import SwitchComponent from '../CoachComponents/switch';
import Nutrition from '../CoachComponents/Nutrition/Nutrition';
import Food from '../CoachComponents/Food/Food';
import Email from '../CoachComponents/Email/Email';
import MenuCard from '../CoachComponents/Food/MenuCard';
import SuccessPage from '../CoachComponents/Food/SucessPage';
import ExerciseCard from '../CoachComponents/Food/ExerciseCard';
import Activitiess from '../CoachComponents/Display/Activities/Activities';
import CreatePack from '../CoachComponents/Display/Pricing/CreatePack';
import SocialMediaLinks from '../CoachComponents/Display/SocialMediaLinks/SocialMediaLinks';
import Gallerie from '../CoachComponents/Display/Gallerie/Gallerie';
import FAQ from '../CoachComponents/FAQ/faq';
import Contact from '../CoachComponents/Contact/contact';
import Security from '../CoachComponents/Settings/Security/security';
import NotificationSettings from '../CoachComponents/Settings/Notification/notification';
import AccountDetails from '../CoachComponents/Settings/Accountdetails/accountdetails';
import AddUsers from '../CoachComponents/AddCoachs/AddUsers';
import SuperUserDetails from '../CoachComponents/Request/Profileuser';

const CoachLayoutRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutCoach />}>
        <Route path="/Overviewcoach" element={<Overviewcoach />} />
        <Route path="/coach/subscription/Addreq" element={<AddUsers />} />
        <Route path="/coach/display/coachDetails" element={<CoachDetails />} />
        <Route path="/coach/display/SocialMediaLinks" element={<SocialMediaLinks />} />
        <Route path="/coach/display/activitiess" element={<Activitiess />} />
        <Route path="/coach/display/Gallerie" element={<Gallerie />} />
        <Route path="/coach/display/CreatePack" element={<CreatePack />} />
        <Route path="/coach/Request/SuperUserDetails" element={<SuperUserDetails />} />
        <Route path="/coach/Calendar" element={<DraggableContain />} />
        <Route path="/coach/switch/:id" element={<SwitchComponent />} />
        <Route path="/coach/Nutrition" element={<Nutrition />} />
        <Route path="/coach/Food" element={<Food />} />
        <Route path="/coach/Email" element={<Email />} />
        <Route path="/coach/MenuCard" element={<MenuCard />} />
        <Route path="/coach/success" element={<SuccessPage />} />
        <Route path="/coach/ExerciseCard" element={<ExerciseCard />} />
        <Route path="/coach/FAQ" element={<FAQ />} />
        <Route path="/coach/Contact" element={<Contact />} />
        <Route path="/coach/Settings/accoundetails" element={<AccountDetails />} />
        <Route path="/coach/Settings/notification" element={<NotificationSettings />} />
        <Route path="/coach/Settings/security" element={<Security />} />
      </Route>
    </Routes>
  );
};

export default CoachLayoutRoutes;